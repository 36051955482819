import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";


import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material';


export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#232157',
    },
    secondary: {
        main: '#F6433B',
      },
      background: {
        paper: '#fff',
        default: '#f8f8f9',
      },
      text: {
        primary: 'rgba(255,255,255,0.87)',
        secondary: '#C2C1E6',
        disabled: '#C2C1E6'
      },
      mode: 'dark'
  },
  
  typography: {
    allVariants: {
      fontFamily: 'Lexend Deca',
      textTransform: 'none',
      fontSize: 15,
      fontWeight: 300,
      color: 'var(--bg--dark)'
    },
  }
};
  
const root = ReactDOM.createRoot(
  document.getElementById('root')  as HTMLElement
  );

const theme = createTheme(themeOptions);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ ThemeProvider>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
