import { Reducer } from "react";
import { ALERTS_REDUX } from "../../constants";
import { AlertActions, AlertsInterface } from "./types";

export const initialState: AlertsInterface = {
    success: {
        message: ''
    },
    error: {
        message: ''
    },
    loader: {
        visible: false
    },
}

export const alertsReducer: Reducer<AlertsInterface, AlertActions> = (state = initialState, { type, ...payload }: any) => {
    switch (type) {
        case ALERTS_REDUX.SHOW_SUCCESS_ALERT:
            return {
                ...state,
                success: { message: payload.payload }
            };
        case ALERTS_REDUX.SHOW_ERROR_ALERT:
            return {
                ...state,
                error: { message: payload.payload }
            };
        case ALERTS_REDUX.SHOW_LOADER:
            return {
                ...state,
                loader: { visible: true }
            };
        case ALERTS_REDUX.HIDE_LOADER:
            return {
                ...state,
                loader: { visible: false }
            };
        default:
            return {
                ...state
            };
    }
};
