export const ALERTS_REDUX = {
    SHOW_ERROR_ALERT: 'SHOW_ERROR_ALERT',
    SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
};

export const USER_REDUX_CONST = {
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
}

export const PROJECT_REDUX_CONST = {
    SAVE_LIST: 'SAVE_LIST',
    UPDATE_PROJECT_KEY: 'UPDATE_PROJECT_KEY',
    SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
    ACTIVATE_INACTIVATE_PROJECT: 'ACTIVATE_INACTIVATE_PROJECT',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
    UPDATE_PROJECT_TITLE: 'UPDATE_PROJECT_TITLE'
}

export const PROJECT_TOKEN_REDUX_CONST = {
    GET_ALL_TOKEN_ALL: "GET_ALL_TOKEN_ALL",
    CURRENT_PROJECT: "CURRENT_PROJECT",
    ACTIVATE_INACTIVATE_SERVICES: "ACTIVATE_INACTIVATE_SERVICES",
    UPDATE_GOOGLE_PUSH_TOKEN: "UPDATE_GOOGLE_PUSH_TOKEN",
    UPDATE_TWILIO_TOKEN: "UPDATE_TWILIO_TOKEN",
    UPDATE_SOCIAL_TOKEN: "UPDATE_SOCIAL_TOKEN",
    UPDATE_AWS_TOKEN: "UPDATE_AWS_TOKEN",
    UPDATE_SMTP_TOKEN: 'UPDATE_SMTP_TOKEN',
};


export const RESET = 'RESET'; 