import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";

//custome imports 
import { ShowErrorAlert, ShowSuccessAlert } from "../../store/alerts";
import { RootStateType } from "../../store";

const styles = {
  alertPopStyle: {
    width: "100%",
    color: "#fff",
    background: "#5AAA77",
    fontWeight: 400,
  },
  alertPopStyle2: {
    width: "100%",
    color: "#fff",
    background: "#EB3751",
    fontWeight: 400,
  }
}

export default function AlertPopUpComponent() {
  const dispatch = useDispatch();
  const alerts = useSelector((state: RootStateType) => state.alerts);

  const handleClose = () => {
    dispatch(ShowSuccessAlert(""));
  };

  const handleErrorClose = () => {
    dispatch(ShowErrorAlert(""));
  };

  return (
    <>
      <Snackbar
        className="red--whiteIcon"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(alerts.success.message)}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={styles.alertPopStyle}
        >
          {alerts.success.message}
        </Alert>
      </Snackbar>
      <Snackbar
        className="red--whiteIcon"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(alerts.error.message)}
        autoHideDuration={3000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={styles.alertPopStyle2}
        >
          {alerts.error.message}
        </Alert>
      </Snackbar>
    </>
  );
}
