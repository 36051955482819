import { combineReducers } from 'redux';

import {alertsReducer} from "./alerts";
import { userInfoReducer } from './user';
import { projectsInfoReducer } from './projects';
import { projectsTokenReducer } from './projectToken';

const rootReducer = combineReducers({
    alerts: alertsReducer,
    userInfo : userInfoReducer,
    projects : projectsInfoReducer,
    projectsToken:projectsTokenReducer
});

export default rootReducer;