import { Reducer } from "react";
import { PROJECT_TOKEN_REDUX_CONST, RESET } from "../../constants";
import {
  Actions,
  aws_info,
  google_push_info,
  projectTokenPayloadInterface,
  social_auth_info,
  twilio_info,
  smtp_info

} from "./types";
import { smtpDataType } from "../../models/smtp.data";
import { twilioDataType } from "../../models/twilio.data";
import { snsDataType } from "../../models/sns.data";

export interface initialStateDataType {
  projectId: string;
  projects: {
    aws_info: aws_info;
    google_push_info: google_push_info;
    social_auth_info: social_auth_info;
    twilio_info: twilioDataType;
    smtp_info: smtpDataType;
    sns_info: snsDataType;
  };
}

export const initialState: initialStateDataType = {
  projectId: "",
  projects: {
    aws_info: {
      access_token: "",
      secret_token: "",
      region: "",
      bucket_name: "",
      is_active: false,
    },
    google_push_info: {
      googlePush: "",
      is_active: false,
    },
    social_auth_info: {
      googleToken: "",
      appleToken: "",
      is_active: false,
    },
    twilio_info: {
      // Twilio data filed 
      sid: "",
      phone_number: "",
      auth_token: "",
      // thresholds data field 
      threshold_minute: 0,
      threshold_hour: 0,
      threshold_day: 0,
      daily_system_limit: 0,
      // SNS data filed 
      sns_access_token: "",
      sns_secret_token: "",
      sns_region: "",
      // Taqnayt data field 
      taqnyat_sender_name: "",
      taqnyat_bearer_token: "",
      // custom text fields 
      service_type: "",
      optional_client_ip: false,
      is_active: false
    },
    smtp_info: {
      host_server: "",
      smtp_port: "",
      smtp_user_name: "",
      smtp_server_password: "",
      smtp_email: "",
      is_active: false,
      daily_email_limit: "",
      user_daily_limit: ""
    },
    sns_info: {
      access_token: "",
      secret_token: "",
      region: "",
      threshold_minute: 0,
      threshold_hour: 0,
      threshold_day: 0,
      daily_system_limit: 0,
      optional_client_ip: false,
      is_active: false
    }
  },
};


//@ts-ignore
export const projectsTokenReducer: Reducer<projectTokenPayloadInterface, Actions> = (state = initialState, { type, ...payload }: any) => {
  switch (type) {
    case PROJECT_TOKEN_REDUX_CONST.GET_ALL_TOKEN_ALL:
      return {
        ...state,
        projects: { ...(payload.payload.projects || {}) },
      };
    case PROJECT_TOKEN_REDUX_CONST.CURRENT_PROJECT:
      return {
        ...state,
        projectId: payload.payload.projectId || "",
      };
    case PROJECT_TOKEN_REDUX_CONST.ACTIVATE_INACTIVATE_SERVICES:
      const { service_id } = payload.payload;
      return {
        ...state,
        projects: {
          ...state.projects,
          aws_info: {
            ...state.projects.aws_info,
            is_active:
              service_id === "4"
                ? !state.projects.aws_info.is_active
                : state.projects.aws_info.is_active,
          },
          google_push_info: {
            ...state.projects.google_push_info,
            is_active:
              service_id === "2"
                ? !state.projects.google_push_info.is_active
                : state.projects.google_push_info.is_active,
          },
          social_auth_info: {
            ...state.projects.social_auth_info,
            is_active:
              service_id === "3"
                ? !state.projects.social_auth_info.is_active
                : state.projects.social_auth_info.is_active,
          },
          twilio_info: {
            ...state.projects.twilio_info,
            is_active:
              service_id === "1"
                ? !state.projects.twilio_info.is_active
                : state.projects.twilio_info.is_active,
          },
          smtp_info: {
            ...state.projects.smtp_info,
            is_active:
              service_id === "5"
                ? !state.projects.smtp_info.is_active
                : state.projects.smtp_info.is_active,
          },
          sns_info: {
            ...state.projects.sns_info,
            is_active:
              service_id === "6"
                ? !state.projects.sns_info.is_active
                : state.projects.sns_info.is_active,
          },
        },
      };
    case PROJECT_TOKEN_REDUX_CONST.UPDATE_GOOGLE_PUSH_TOKEN:
      return {
        ...state,
        projects: {
          ...state.projects,
          google_push_info: {
            ...state.projects.google_push_info,
            googlePush: payload.payload.push_token,
          },
        },
      };
    case PROJECT_TOKEN_REDUX_CONST.UPDATE_AWS_TOKEN:
      return {
        ...state,
        projects: {
          ...state.projects,
          aws_info: {
            ...state.projects.aws_info,
            access_token: payload.payload.access_token,
            secret_token: payload.payload.secret_token,
            region: payload.payload.region,
            bucket_name: payload.payload.bucket_name,
          },
        },
      };
    case PROJECT_TOKEN_REDUX_CONST.UPDATE_SOCIAL_TOKEN:
      return {
        ...state,
        projects: {
          ...state.projects,
          social_auth_info: {
            ...state.projects.social_auth_info,
            googleToken: payload.payload.google_token,
            appleToken: payload.payload.apple_token,
          },
        },
      };
    case PROJECT_TOKEN_REDUX_CONST.UPDATE_TWILIO_TOKEN:
      return {
        ...state,
        projects: {
          ...state.projects,
          twilio_info: payload.payload
        }
      };


    case "UPDATE_SNS_TOKEN":
      return {
        ...state,
        projects: {
          ...state.projects,
          sns_info: payload.payload
        }
      };



    case PROJECT_TOKEN_REDUX_CONST.UPDATE_SMTP_TOKEN:
      return {
        ...state,
        projects: {
          ...state.projects,
          smtp_info: {
            ...state.projects.smtp_info,

            host_server: payload.payload.host_server,
            smtp_port: payload.payload.smtp_port,
            smtp_user_name: payload.payload.smtp_user_name,
            smtp_server_password: payload.payload.smtp_server_password,
            smtp_email: payload.payload.smtp_email,
            daily_email_limit: payload.payload.daily_email_limit
          },
        },
      };
    case RESET:
      return {
        ...state,
      };
    default:
      return state;
  }
};
