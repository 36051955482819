import React, { useContext } from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Suspense } from 'react';
import Loader from './components/common/Loader';
import { useSelector } from 'react-redux';
import { RootStateType } from './store';

const SignIn = React.lazy(() => import('./pages/Signin/SignIn'));
const DashboardLayout = React.lazy(() => import('./pages/Layout/DashboardLayout'));



export const PrivateRoute = ({ children, ...rest }: any) => {
  let userInfo = useSelector((state: RootStateType) => state.userInfo);
  let accessToken: string = userInfo.accessToken;

  if (!accessToken) {
    return <Navigate to={{ pathname: "/" }} />
  }
  return children;
}

export const IsLoggedIn = ({ children, ...rest }: any) => {
  let userInfo = useSelector((state: RootStateType) => state.userInfo);
  let accessToken: string = userInfo.accessToken;

  let projectArry = useSelector(
    (state: RootStateType) => state?.projects?.projects
  );

  if (accessToken) {
    if (projectArry.length > 0) {
      return <Navigate to={{ pathname: "/project/" + projectArry[0].id }} />
    }
  }
  return children;
}



export default function Routing() {

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              // <IsLoggedIn>
              <Suspense fallback={<Loader />}>
                <IsLoggedIn>
                  <SignIn />
                </IsLoggedIn>
              </Suspense>
              // </IsLoggedIn>
            }
          />
          <Route
            path="/*"
            element={
              <Suspense fallback={<Loader />}>
                <PrivateRoute>
                  <DashboardLayout />
                </PrivateRoute>
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
}


