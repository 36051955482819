import { Store } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { RootStateInterface } from "./RootState";

export interface StoreConfig {
  store: Store<RootStateInterface>;
  persistor: Persistor;
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["alerts"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;
