import { Reducer } from "react";
import { PROJECT_REDUX_CONST, RESET } from "../../constants";
import { Actions, projectListInfoParamsInterface } from "./types";

export const initialState: projectListInfoParamsInterface = {
    projects: [],
    currentProject: {
        id: "0",
        projectName: "",
        key: "",
        isActive: false
    }
}

//@ts-ignore
export const projectsInfoReducer: Reducer<projectListInfoParamsInterface, Actions> = (state = initialState, { type, ...payload }: any) => {
    switch (type) {
        case PROJECT_REDUX_CONST.SAVE_LIST:
            return {
                ...state,
                projects: [...payload.payload || []]
            };

        case PROJECT_REDUX_CONST.UPDATE_PROJECT_KEY:
            const { projectId, key } = payload.payload
            return {
                ...state,
                projects: state.projects.map(obj => {
                    if (obj.id === projectId) {
                        obj.key = key
                        return { ...obj }
                    };
                    return obj;
                }),
                currentProject: {
                    ...state.currentProject,
                    key: payload.payload.key
                }

            };

        case PROJECT_REDUX_CONST.SET_CURRENT_PROJECT:
            return {
                ...state,
                currentProject: state.projects.find(obj => obj.id === payload.payload.projectId)
            };

        case PROJECT_REDUX_CONST.ACTIVATE_INACTIVATE_PROJECT:
            return {
                ...state,
                currentProject: {
                    ...state.currentProject,
                    isActive: !state.currentProject.isActive
                },
                projects: state.projects.map(obj => {
                    if (obj.id === state.currentProject.id) {
                        obj.isActive = !obj.isActive
                        return { ...obj }
                    };
                    return obj;
                }),
            };


        case PROJECT_REDUX_CONST.REMOVE_PROJECT:
            let setCurrentProject

            if (state.currentProject.id === payload.payload) {
                const currentIndex = state.projects.findIndex(project => project.id === state.currentProject.id);
                let newIndex;

                if (currentIndex == state.projects.length - 1) {
                    newIndex = currentIndex - 1;
                } else {
                    newIndex = currentIndex;
                }

                setCurrentProject = state.projects[newIndex];
            } else {
                setCurrentProject = state.currentProject
            }

            return {
                ...state,
                projects: state.projects.filter(obj => obj.id !== payload.payload),
                currentProject: setCurrentProject
            };


        case "UPDATE_PROJECT_TITLE":

            return {
                ...state,
                projects: state.projects.map(obj => {
                    if (obj.id === state.currentProject.id) {
                        obj.projectName = payload.payload
                        return { ...obj }
                    };
                    return obj;
                }),
                currentProject: {
                    ...state.currentProject,
                    projectName: payload.payload
                }
            };

        case RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
