import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store/RootState';
import { Box, LinearProgress } from '@mui/material';

export default function Loader(props:any) {
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', backgroundColor:"rgba (0,0,0,0.64)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}


export function ProgressBar(props:any) {
    const alerts = useSelector((state: RootStateType) => state.alerts);
    return (
        <>
            {
            alerts.loader.visible 
                ? 
                    <Box component={'div'} sx={{ width: '100%', position:'fixed', zIndex:"9999" }}>
                        <LinearProgress sx = {{
                            backgroundColor: 'var(--brand--font--link)'
                        }}/>
                    </Box> 
                 : 
                    ''
            } 
        </>
    )
}
