import { Reducer } from "react";
import { USER_REDUX_CONST, RESET } from "../../constants";
import { Actions, userInfoParamsInterface } from "./types";

export const initialState:userInfoParamsInterface = {
    username: "",
    userEmail: "",
    accessToken: "",
    refreshToken: ""
}

export const userInfoReducer: Reducer<userInfoParamsInterface, Actions> = (state = initialState, { type, ...payload }: any) => {
    switch (type) {
        case USER_REDUX_CONST.UPDATE_USER_INFO:
            const { username, userEmail, accessToken, refreshToken } = payload.payload
            return {
                ...state,
                username: username,
                userEmail: userEmail,
                accessToken: accessToken,
                refreshToken: refreshToken,
            };
      
        case RESET:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
